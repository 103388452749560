<template>
  <div class="form">
    <div class="wrapper illustration illustration_brackets">
      <Backlink title="detail" service="refMortgage" backlink="/refinance-mortgage/about-problems" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="question">{{ "rm_s4p14_title" | localize }}</p>

            <v-checkbox v-model="reason_1" hide-details on-icon="icon-ckeckbox-on" off-icon="icon-ckeckbox-off" :class="{ invalid: noSelect }" @change="checkSelect()">
              <template v-slot:label>
                <input type="hidden" ref="sale" />
                {{ "rm_s4p14_radio_1" | localize }}
              </template>
            </v-checkbox>

            <v-checkbox v-model="reason_2" hide-details on-icon="icon-ckeckbox-on" off-icon="icon-ckeckbox-off" :class="{ invalid: noSelect }" @change="checkSelect()">
              <template v-slot:label>
                <input type="hidden" ref="sale" />
                {{ "rm_s4p14_radio_2" | localize }}
              </template>
            </v-checkbox>

            <v-checkbox v-model="reason_3" hide-details on-icon="icon-ckeckbox-on" off-icon="icon-ckeckbox-off" :class="{ invalid: noSelect }" @change="checkSelect()">
              <template v-slot:label>
                <input type="hidden" ref="sale" />
                {{ "rm_s4p14_radio_3" | localize }}
              </template>
            </v-checkbox>

            <v-checkbox v-model="reason_4" hide-details on-icon="icon-ckeckbox-on" off-icon="icon-ckeckbox-off" :class="{ invalid: noSelect }" @change="checkSelect()">
              <template v-slot:label>
                <input type="hidden" ref="sale" />
                {{ "rm_s4p14_radio_4" | localize }}
              </template>
            </v-checkbox>

            <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler">
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

const refMortgage = VueCookies.get('refMortgage') || {}

export default {
  name: 'Refinance_mortgage_reason',
  components: { Backlink },
  mixins: [commonMixin],
  data: () => ({
    reason_1: refMortgage.reason_1 || null,
    reason_2: refMortgage.reason_2 || null,
    reason_3: refMortgage.reason_3 || null,
    reason_4: refMortgage.reason_4 || null,
    noSelect: false,
    success: false
  }),
  methods: {
    checkSelect () {
      this.noSelect = !this.reason_1 && !this.reason_2 && !this.reason_3 && !this.reason_4
      this.success = !this.noSelect
    },
    submitHandler () {
      this.checkSelect()
      if (this.noSelect) {
        this.success = false
        return
      }

      this.success = true
      refMortgage.reason_1 = this.reason_1
      refMortgage.reason_2 = this.reason_2
      refMortgage.reason_3 = this.reason_3
      refMortgage.reason_4 = this.reason_4
      this.$cookies.set('refMortgage', refMortgage)

      const droppedSteps = [
        '/refinance-mortgage/type',
        '/refinance-mortgage/increase',
        '/refinance-mortgage/decrease',
      ]

      // Сброс следующих шагов линии действий
      this.dropSteps('refMortgage', this.$router.currentRoute.path, droppedSteps[0])

      // Переход в зависимости от выбора
      if (this.reason_4) {
        this.$router.push('/refinance-mortgage/type')
      } else {
        this.addMissedSteps('refMortgage', droppedSteps)
        this.$router.push('/refinance-mortgage/something-else')
      }
    }
  }
}
</script>
